import { Link, To } from "react-router";
import Skeleton from "react-loading-skeleton";
import { ReactNode, useContext } from "react";
import { LoaderContext } from "@/contexts/loaderContext";

type NavOptionProps = {
  readonly to: To;
  readonly text: string;
  readonly current: boolean;
  readonly protectedRoute: boolean;
}
export default function NavOption({ to, text, current, protectedRoute }: NavOptionProps) {
  const loading = useContext(LoaderContext);

  if (loading) {
    return <ListElement current={false}>
      <NavOptionLoading />
    </ListElement>;
  }

  if (protectedRoute) {
    return <ListElement current={current}>
      <span className={"nav-link disabled"}>{text}</span>
    </ListElement>;
  }

  return <li className={`${current && !loading ? "current active" : ""}`}>

    <Link to={to} className="nav-link">
      <span>{text}</span>
    </Link>
  </li>;
}

function NavOptionLoading() {
  return <a className={"nav-link"} href={"/"}>
    <Skeleton width={90} inline={true} style={{ paddingBottom: 4 }} />
  </a>;
}

function ListElement({ children, current }: { children?: ReactNode, current: boolean }) {
  return <li className={`${current ? "current active" : ""}`}>
    {children}
  </li>;
}
