import { getAktoerKontaktFullname, getKontaktFullname } from "@/util/aktoer";
import { Kontakt } from "@/types/kontakt";
import { AktoerDto } from "@/types/aktoerDto";

export const sortAktoerKontaktAlphabetically = (aktoerA: AktoerDto, aktoerB: AktoerDto) => {
  if (getAktoerKontaktFullname(aktoerA) < getAktoerKontaktFullname(aktoerB)) {
    return -1;
  }
  if (getAktoerKontaktFullname(aktoerA) > getAktoerKontaktFullname(aktoerB)) {
    return 1;
  }
  return 0;
};

export const sortKontaktAlphabetically = (aktoerA: Kontakt, aktoerB: Kontakt) => {
  if (getKontaktFullname(aktoerA) < getKontaktFullname(aktoerB)) {
    return -1;
  }
  if (getKontaktFullname(aktoerA) > getKontaktFullname(aktoerB)) {
    return 1;
  }
  return 0;
};

export const uniqueByProperty = <T, K extends keyof T>(objects: T[], prop: K): T[] => {
  const seen = new Set<T[K]>();
  return objects.reduce((acc: T[], object) => {
    const property = object[prop];
    if (!seen.has(property)) {
      seen.add(property);
      acc.push(object);
    }
    return acc;
  }, []);
};
