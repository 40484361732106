import { UserInfo } from "@/types/userinfo";
import SolutionInfo from "./SolutionInfo";
import React from "react";
import PortalInfo from "@/components/header/PortalInfo";
import PortalInfoMobile from "@/components/header/mobile/PortalInfoMobile";
import SolutionInfoMobile from "@/components/header/mobile/SolutionInfoMobile";
import NavContainer from "@/components/nav/NavContainer";
import Nav from "@/components/nav/Nav";
import Icon from "@/components/icons/Icon";

export default function Header({ userinfo }: { readonly userinfo?: UserInfo }) {
  return (
    <header className="header">
      <a href="#main-content" className="skipnav">
        Gå til sidens indhold
      </a>
      <PortalInfo user={userinfo} />
      <SolutionInfo />
      <NavContainer userinfo={userinfo} />


      <div className="overlay"></div>
      <div className="mobile-drawer">
        <div className="menu-top">
          <h2 id="menu-heading" className="menu-heading">Menu</h2>
          <button className="function-link button-menu-close js-menu-close"
                  aria-label="Luk menu">
            <Icon icon={"#close"} />
            Luk
          </button>
        </div>
        <SolutionInfoMobile />
        <Nav className={"navigation-menu-mobile"} userinfo={userinfo} />
        <PortalInfoMobile userinfo={userinfo} />
      </div>
    </header>
  );
}
