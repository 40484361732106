import SkiftBrugernavnBase from "@/components/skiftBrugernavn/SkiftBrugernavnBase";
import StatusBesked from "@/components/besked/StatusBesked";
import { PATHS } from "@/constants/paths";
import { Link } from "react-router";
import { Kontakt } from "@/types/kontakt";

type SkiftBrugernavnNotActivatedType = {
  kontakt: Kontakt,
}

export default function SkiftBrugernavnNotActivated({ kontakt }: SkiftBrugernavnNotActivatedType) {
  const getEjefaldFirstname = ["s", "z", "x"].some(char => kontakt.fornavn.endsWith(char)) ?
    kontakt.fornavn + "'" : kontakt.fornavn + "'s";

  return <SkiftBrugernavnBase onClick={() => null} skiftBrugernavnDisabled={true} kontakt={kontakt}>
    <StatusBesked type={"info"}
                  overskrift={kontakt.fornavn + " mangler aktivering"}>
      {kontakt.fornavn} mangler at blive aktiveret i Unilogin før der kan skiftes brugernavn.
      Tag kontakt til {getEjefaldFirstname} institution. Kontaktinformationer kan findes under menupunktet <Link
      to={PATHS.MIN_SKOLE}>Min Skole</Link>.
    </StatusBesked>
  </SkiftBrugernavnBase>;
};
