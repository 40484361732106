import { sortKontaktAlphabetically, uniqueByProperty } from "@/util/sort";
import { AktoerDto } from "@/types/aktoerDto";

export const useAktoerKontakt = (aktoerer: AktoerDto[]) => {
  const hasKontakter = !!aktoerer.find(aktoer => aktoer.elevForKontaktperson);
  const getKontakter = aktoerer
  .flatMap(aktoer => aktoer.elevForKontaktperson)
  .filter(kontakt => kontakt)
  .sort(sortKontaktAlphabetically);
  const getCurrentKontakt = (aktoerId: string) => {
    return getKontakter.find(kontakt => kontakt.aktoerUuid === aktoerId);
  };

  const getAggregratedKontakter = () => uniqueByProperty(getKontakter, "uniId");

  const primaerKontaktAktoers = getKontakter.filter(kontakt => kontakt.primaerKontaktAktoer);

  return {
    hasKontakter,
    getKontakter,
    getCurrentKontakt,
    getAggregratedKontakter,
    primaerKontaktAktoers
  };
};
