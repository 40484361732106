import Footer from "@/components/footer/Footer";
import Header from "@/components/header/Header";
import { UserContext } from "@/contexts/userContext";
import { UserInfo } from "@/types/userinfo";
import { ReactElement } from "react";
import { Outlet } from "react-router";
import ToastContainer from "@/components/toast/Toasts";
import { LoaderContext } from "@/contexts/loaderContext";

type LayoutType = {
  userinfo: UserInfo,
  children?: ReactElement;
  loading?: boolean;
}

export default function Layout({ userinfo, children, loading = false }: LayoutType) {

  return (
    <>
      <LoaderContext.Provider value={loading}>
        <Header userinfo={userinfo} />

        <div className={"container page-container"}>
          <main className="content" id="main-content">
            <UserContext.Provider value={userinfo}>

              <ToastContainer />
              {children}
              <Outlet />

            </UserContext.Provider>
          </main>
        </div>
        <Footer />
      </LoaderContext.Provider>
    </>
  );
}
