import HandWave from "@/components/emoji/HandWave";
import MitUnilogin from "@/components/logo/MitUnilogin";
import { useRouteLoaderData } from "react-router";
import { FeatureToggleConfiguration, UserInfo } from "@/types/userinfo";
import { useAktoerKontakt } from "@/hooks/useAktoerKontakt";
import OversigtCards from "@/views/oversigt/OversigtCards";
import Skeleton from "react-loading-skeleton";
import { LoaderContext } from "@/contexts/loaderContext";
import { useContext } from "react";

export default function Oversigt() {
  const loading = useContext(LoaderContext);
  const { featureToggles, hasKontakter, isLoggedIn, aktoerOverblik } = getOversigtData(loading);

  if (loading) {
    return <>
      <h1>
        <Skeleton />
      </h1>
      <p role="heading" aria-level={2}><Skeleton /></p>
      <Cards featureToggles={featureToggles} hasKontakter={hasKontakter} isLoggedIn={isLoggedIn} />
    </>;
  }

  if (!isLoggedIn) {
    return <>
      <h1>
        Velkommen til <MitUnilogin />
      </h1>
      <p role="heading" aria-level={2}>Her på <MitUnilogin /> kan du logge på og se, hvilke skoler og fritidsinstitutioner du
        går på. <br /><br />Du har også mulighed for at se hvilke information vi har registreret på dig.</p>
      <Cards featureToggles={featureToggles} hasKontakter={hasKontakter} isLoggedIn={isLoggedIn} />
    </>;
  }

  return (
    <>
      <h1>
        {!loading ? <>Hej {aktoerOverblik.navn}, du er logget på <MitUnilogin /> <HandWave /></> : <Skeleton />}
      </h1>
      <p role="heading" aria-level={2}>{!loading ? <>Her kan du se de oplysninger, som vi har om dig.</> : <Skeleton />}</p>
      <Cards featureToggles={featureToggles} hasKontakter={hasKontakter} isLoggedIn={isLoggedIn} />
    </>
  );
}

function Cards({ featureToggles, hasKontakter, isLoggedIn }: {
  featureToggles: FeatureToggleConfiguration,
  hasKontakter: boolean,
  isLoggedIn: boolean
}) {

  return <div className="row">
    <OversigtCards featureToggles={featureToggles} hasKontakter={hasKontakter} isLoggedIn={isLoggedIn} />
  </div>;
}

const getOversigtData = (loading: boolean) => {
  const { aktoerOverblik, featureToggles, isLoggedIn } = !loading ? useRouteLoaderData("main") as UserInfo : {
    aktoerOverblik: { navn: "", aktoerer: [], statusCode: null } as UserInfo["aktoerOverblik"],
    featureToggles: {
      skiftBrugernavn: false,
    } as FeatureToggleConfiguration
  };
  const { hasKontakter } = useAktoerKontakt(aktoerOverblik.aktoerer);

  return { aktoerOverblik, featureToggles, hasKontakter, isLoggedIn };
};
