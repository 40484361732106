import { NAVIGATION_PATHS } from "@/constants/paths";
import LinkCard from "@/components/cards/LinkCard";
import { FeatureToggleConfiguration } from "@/types/userinfo";
import LoginButton from "@/components/buttons/LoginButton";
import { Link } from "react-router";

type OversigtCardsType = {
  hasKontakter: boolean;
  featureToggles: FeatureToggleConfiguration,
  isLoggedIn: boolean;
}

export default function OversigtCards({ hasKontakter, featureToggles, isLoggedIn }: OversigtCardsType) {

  const getLinkCards = () => {
    if (!isLoggedIn) {
      return <div className="col-12 col-sm-6 col-md-4 mb-6">
        <LoginButton />
      </div>;
    }
    const paths = () => {
      return NAVIGATION_PATHS.filter((nav) => !nav.hideOnIndex).filter(nav => {
        if (nav.hideOnNoKontakt) {
          return !!hasKontakter;
        }
        if (nav.featureToggle !== undefined) {
          return featureToggles[nav.featureToggle];
        }
        return nav;
      });
    };
    return paths().map((path) => {
      return (
          <div className="col-12 col-sm-6 col-md-4 mb-6" key={path.path}>
            <LinkCard overskrift={path.text} beskrivelse={path.description}>
              <Link to={path.path}>{path.text}</Link>
            </LinkCard>
          </div>

      );
    });
  };
  return getLinkCards();
}
