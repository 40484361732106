import SkiftBrugernavnBase from "@/components/skiftBrugernavn/SkiftBrugernavnBase";
import { useRef } from "react";
import BaseDialog from "@/components/dialog/BaseDialog";
import { updateUsername } from "@/services/UserinfoService";
import { Kontakt } from "@/types/kontakt";
import { createUpdateUsernameToastError, createUpdateUsernameToastSuccess } from "@/util/toasts";
import useToastStore from "@/store/toastStore";

type SkiftBrugernavnType = {
  kontakt: Kontakt;
}

export default function SkiftBrugernavn({ kontakt }: SkiftBrugernavnType) {
  const { addToast } = useToastStore();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const skiftBrugernavn = async () => {
    const result = await updateUsername(kontakt.aktoerUuid);
    if (result.success) {
      kontakt.username = result.username
      addToast(createUpdateUsernameToastSuccess(result));
    } else {
      addToast(createUpdateUsernameToastError(result));
    }
    dialogRef.current.close();
  };
  return <>
    <BaseDialog ref={dialogRef} header={"Skift brugernavn"}
                body={`Ønsker du at skifte brugernavn for ${kontakt.fornavn} så tryk på 'Skift brugernavn'. ${kontakt.fornavn} vil få et nyt
                  brugernavn automatisk. Denne handling kan ikke fortrydes.`} submitCallback={skiftBrugernavn}
                submitButtonText={"Skift brugernavn"} close={() => dialogRef.current.close()} />
    <SkiftBrugernavnBase onClick={() => dialogRef.current.showModal()} skiftBrugernavnDisabled={false} kontakt={kontakt}>

    </SkiftBrugernavnBase>
  </>;
};
