import { createBrowserRouter, RouteObject } from "react-router";
import { PATHS } from "@/constants/paths";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import Main from "@/views/app/Main";
import AktoerOverblik from "@/views/aktoeroverblik/AktoerOverblik";
import Oversigt from "@/views/oversigt/Oversigt";
import { getUserInfo } from "@/services/UserinfoService";
import ServerFejl from "@/views/error/ServerFejl";
import NotFoundFejl from "@/views/error/NotFoundFejl";
import LoginHistorik from "@/components/loginhistorik/LoginHistorik";
import KontaktLoginHistorik from "@/components/loginhistorik/KontaktLoginHistorik";
import KontaktLoginHistorikContainer from "@/components/loginhistorik/KontaktLoginHistorikContainer";
import LoginDetaljeContainer from "@/components/loginhistorik/logindetaljer/LoginDetaljeContainer";
import KontaktLoginDetaljeContainer from "@/components/loginhistorik/logindetaljer/KontaktLoginDetaljeContainer";
import FeatureToggledRoute from "@/components/protectedRoute/FeatureToggledRoute";
import SkiftBrugernavnView from "@/views/skiftBrugernavn/SkiftBrugernavnView";
import SkiftBrugernavnLoader from "@/components/skiftBrugernavn/SkiftBrugernavnLoader";
import Layout from "@/views/layout/Layout";
import { EMPTY_USERINFO } from "@/constants/errors";

export default function getRouter() {
  return createBrowserRouter(routes);
}

export const routes: RouteObject[] = [
  {
    path: PATHS.FRONT,
    element: <Main />,
    id: "main",
    loader: async () => await getUserInfo(),
    errorElement: <ServerFejl />,
    hydrateFallbackElement: <Layout userinfo={EMPTY_USERINFO} loading={true}>
      <Oversigt />
    </Layout>,
    children: [
      {
        path: PATHS.FRONT,
        element: (
          <Oversigt />
        ),
      },
      {
        path: PATHS.MIN_SKOLE,
        element: (
          <ProtectedRoute>
            <AktoerOverblik />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.LOGIN_HISTORIK,
        element: (
          <ProtectedRoute>
            <LoginHistorik />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.LOGIN_HISTORIK_DETALJER,
        element: (
          <ProtectedRoute>
            <LoginDetaljeContainer />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.KONTAKT_LOGIN_HISTORIK,
        element: (
          <ProtectedRoute>
            <KontaktLoginHistorikContainer />
          </ProtectedRoute>
        ),
        children: [
          {
            path: PATHS.KONTAKT_LOGIN_HISTORIK_AKTOER,
            element: <KontaktLoginHistorik />,
          }
        ]
      },
      {
        path: PATHS.SKIFT_BRUGERNAVN,
        element: (
          <ProtectedRoute>
            <FeatureToggledRoute featureToggle={"skiftBrugernavn"}>
              <SkiftBrugernavnView />
            </FeatureToggledRoute>
          </ProtectedRoute>
        ),
        children: [{
          path: PATHS.SKIFT_BRUGERNAVN_BARN,
          element: <SkiftBrugernavnLoader />
        }]
      },
      {
        path: PATHS.KONTAKT_LOGIN_HISTORIK_AKTOER_DETALJER,
        element: <KontaktLoginDetaljeContainer />
      },
      {
        path: PATHS.SERVER_ERROR_PATH,
        element: <ServerFejl />
      },
      {
        path: PATHS.NOT_FOUND_PATH,
        element: <NotFoundFejl />
      },
    ],
  },
];
