import { AktoerDto } from "@/types/aktoerDto";
import { getAktoerCardColor, getKontaktFullname } from "@/util/aktoer";
import React from "react";
import { PATHS } from "@/constants/paths";
import { Link } from "react-router";
import Icon from "../icons/Icon";

export default function AktoerCard({ aktoer }: { readonly aktoer: AktoerDto }) {
  const hasKontaktLogins = () => {
    if (aktoer.elevForKontaktperson) {
      return <Link to={PATHS.KONTAKT_LOGIN_HISTORIK + aktoer.elevForKontaktperson.aktoerUuid}
                   className={"icon-link function-link"}>Gå til logins</Link>;
    }
    return <></>;
  };
  const getContact = () => {
    return (
      aktoer.institution.registerType === "INSTITUTIONS_REGISTER" && (
        <a
          className="icon-link function-link"
          target={"_blank"}
          rel={"noreferrer"}
          href={"https://data.stil.dk/InstregV2/VisInstitution.aspx?InstNr=" + aktoer.institution.institutionId}
        >
          Kontakt institution
          <Icon icon={"#open-in-new"} title={"ikon åben i nyt vindue"} />
        </a>
      )
    );
  };

  const getAktoerFullName = `${aktoer.fornavn} ${aktoer.efternavn}`;

  const getDetails = () => {
    if (aktoer.aktoerType === "Elev") {
      return <p>Du hører til {aktoer.elevHovedgruppeNavn}</p>;
    }
    if (aktoer.aktoerType === "Forælder eller kontaktperson") {
      return <p>Du er kontakt til {getKontaktFullname(aktoer.elevForKontaktperson)}</p>;
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="header-title ellipsis" title={aktoer.institution.navn}>{aktoer.institution.navn}</h3>
        <p className="sub-header">{aktoer.aktoerType}</p>
      </div>
      <div className={"card-content px-5 py-4 aktoer-content " + getAktoerCardColor(aktoer.aktoerType)}>
        <h2 className="displayheading-2 ellipsis" title={getAktoerFullName}>
          {getAktoerFullName}
        </h2>
        {getDetails()}

      </div>
      <div className="card-text">{getContact()}</div>
      <div className="card-text">{hasKontaktLogins()}</div>
    </div>
  );
}
