import { UserInfo } from "@/types/userinfo";
import UserView from "./UserView";
import { Link } from "react-router";
import { PATHS } from "@/constants/paths";
import Icon from "@/components/icons/Icon";

export default function PortalInfo({ user }: { user: UserInfo }) {
  return (
    <div className="portal-info">
      <div className="portal-info-inner container">
        <Link to={PATHS.FRONT} title="Gå til MitUnilogin forside" aria-label="MitUnilogin logo" className="logo">
          <span>MitUnilogin</span>
        </Link>
        <button className="function-link button-menu-open js-menu-open ml-auto d-print-none" aria-haspopup="dialog">
          <Icon icon={"#menu"} />
          Menu
        </button>

        <div className="portal-user"><UserView userinfo={user} /></div>
      </div>
    </div>
  );
}
