import { UserInfo } from "@/types/userinfo";
import LogoutButton from "../buttons/LogoutButton";
import LoginButton from "../buttons/LoginButton";
import { useContext } from "react";
import { LoaderContext } from "@/contexts/loaderContext";
import Skeleton from "react-loading-skeleton";

type UserViewProps = { readonly userinfo?: UserInfo; readonly mobileView?: boolean };

export default function UserView({ userinfo }: UserViewProps) {
  const loading = useContext(LoaderContext);
  return (
    <>
      <p className="user">
        {
          !loading ? <strong className="username weight-semibold">{userinfo.aktoerOverblik?.navn}</strong> :
            <Skeleton width={100} height={16} />
        }
      </p>
      {userinfo.isLoggedIn ? <LogoutButton /> :
        <LoginButton />}
    </>
  );
}
