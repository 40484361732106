import Skeleton from "react-loading-skeleton";
import { ReactNode, useContext } from "react";
import { LoaderContext } from "@/contexts/loaderContext";

type LinkCardType = {
  readonly overskrift: string;
  readonly beskrivelse: string;
  children?: ReactNode;
}

export default function LinkCard({ overskrift, beskrivelse, children }: LinkCardType) {
  const loading = useContext(LoaderContext);
  return (
    <div className="card card-align-height">
      <div className="card-header">
        <h2 className="header-title">{!loading ? overskrift : <Skeleton />}</h2>
      </div>

      <div className="card-text">
        <p>{!loading ? beskrivelse : <Skeleton />}</p>
      </div>

      <div className="card-footer card-action">
        <div className="action-links">
          {!loading ? children  : <Skeleton />}
        </div>
      </div>
    </div>
  );
}
