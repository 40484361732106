import { NAVIGATION_PATHS, NavigationPaths } from "@/constants/paths";
import NavOption from "./NavOption";
import { matchRoutes, useLocation } from "react-router";
import { useAktoerKontakt } from "@/hooks/useAktoerKontakt";
import { UserInfo } from "@/types/userinfo";

export default function Nav({ userinfo, className = "" }: { readonly userinfo: UserInfo, className?: string }) {

  const { pathname } = useLocation();
  const { aktoerOverblik, featureToggles } = userinfo;
  const { hasKontakter } = useAktoerKontakt(aktoerOverblik.aktoerer);
  const currentPath = (path: NavigationPaths) => {
    const currentMatchRoutes = path.currentMatch?.map(current => ({
      path: current ?? path.path
    }));
    const routesToMatch = currentMatchRoutes ? [...currentMatchRoutes, {path: path.path}] : [{path: path.path}];
    return !!matchRoutes(routesToMatch, pathname);
  };

  const getNavPaths = () => {
    return NAVIGATION_PATHS.filter(nav => {
      if (nav.hideOnNoKontakt) {
        return !!hasKontakter;
      }
      if (nav.featureToggle !== undefined) {
        return featureToggles[nav.featureToggle];
      }
      return nav;
    });
  };

  return (
    <nav className={className} aria-label={"Hovedmenu"}>
      <ul className="mainmenu">
        {getNavPaths().map((path) => (
          <NavOption key={`nav${path.path}`} to={path.path} text={path.text}
                     current={currentPath(path)} protectedRoute={path.protected && !userinfo.isLoggedIn} />
        ))}
        <li>
        </li>
      </ul>
    </nav>
  );
}
