import { Kontakt } from "@/types/kontakt";
import { AktoerDto } from "@/types/aktoerDto"

export const getKontaktFullname = (kontakt: Kontakt) =>
  `${kontakt?.fornavn} ${kontakt?.efternavn}`;

export const getAktoerKontaktFullname = (aktoer: AktoerDto) => getKontaktFullname(aktoer.elevForKontaktperson);

export const getAktoerCardColor = (aktoerType: AktoerDto["aktoerType"]) => {
  return aktoerCardColorMap[aktoerType] ?? "soft-blue";
}

const aktoerCardColorMap: { [key in AktoerDto["aktoerType"]]: string } = {
  Ekstern: "soft-yellow",
  "Forælder eller kontaktperson": "soft-blue",
  Medarbejder: "soft-pink",
  Elev: "soft-green",
}

